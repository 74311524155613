$mechanic-list-avatar-diameter: 15%;

.mechanic-list-item {
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  box-shadow: var(--box-shadow-black-sm);
  margin-bottom: var(--border-radius);
  background: linear-gradient(2.1deg, darken($secondary-background, 2%) 5%, darken($secondary-background, 0%) 95.8%);
  transition: box-shadow 0.1s ease-in-out;
}
.mechanic-list-item:hover {
  box-shadow: var(--box-shadow-black);
}

.mechanic-list-item-avatar {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  box-shadow: var(--box-shadow-black);
}

.mechanic-list-info {
  padding: 20px;
}

// Why Choose Us reasons
.reason {
  box-shadow: var(--box-shadow-black-sm);
  padding: 20px;
  border-radius: var(--border-radius);
  margin-bottom: var(--border-radius);
}
