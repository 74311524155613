// User section
.user-section {
  box-shadow: var(--box-shadow-black-sm);
  padding: 20px;
  border-radius: var(--border-radius);
}

.user-section-mobile .accordion-body {
  padding-left: 0px;
  padding-right: 0px;
}

.detail-section {
  top: calc(var(--header-height) + var(--border-radius)) !important;
  position: sticky;
  margin-bottom: var(--border-radius);
}
