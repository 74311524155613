// Loaders
.spinner-border {
  display: grid;
  height: 50px;
  margin: auto;
  margin-top: 24px;
  width: 50px;
}

// Info
.info-div {
  text-align: center;
  padding: 20px;
  color: darken($secondary-background, 70%);
  text-shadow: 1px 1px 20px #ffffff;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.info-div:hover {
  transform: scale(1.1);
}

// Icons
.xxs-icon {
  font-size: 15px;
  margin-right: 5px;
}

.xs-icon {
  font-size: 20px;
  margin-right: 7px;
}

.s-icon {
  font-size: 25px;
  margin-right: 7px;
}

.m-icon {
  font-size: 60px;
}

.l-icon {
  font-size: 80px;
}

.m-icon,
.l-icon {
  color: darken($secondary-background, 40%);
}

// Accordion
.accordion-button,
.accordion-header,
.accordion-button:not(.collapsed),
.accordion-item {
  background-color: inherit;
  border: none;
}

// Button
.badge,
.btn {
  font-size: small;
}

// FAQ
.faq-question {
  margin-bottom: 40px;
}

// Multirow
:root {
  --multirow-shadow-background: rgba(0, 47, 128, 0.75); /* Example: semi-transparent red */
}
