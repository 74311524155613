// Main Image
:root {
  --custom-main-image-info-left: 35% !important;
  --custom-main-image-info-top: 50% !important;
  --custom-main-image-filter-brightness: 0.5 !important;
  --custom-main-image-title-font-size: 38px !important;
  --custom-main-image-title-mobile-font-size: 30px !important;
  --custom-main-image-title-text-align: left !important;
  --custom-main-image-slogan-font-size: 20px !important;
  --custom-main-image-box-shadow: rgb(0 58 157) 0px 0px 200px 50px !important;
}

// Album image
.album-img {
  cursor: pointer;
  border-radius: 3px;
  box-shadow: var(--box-shadow-black-sm);
  width: 100%;
  margin-bottom: 15px;
}

// Avatar
.avatar {
  cursor: pointer;
  width: 150px;
  height: 150px;
  display: flex;
  border-radius: 50%;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 15%);
  overflow: hidden;
  position: absolute;
  transform: translate(calc(50vw - 50%), -50%);
}
