// Colours (Light mode)
$main-background: rgba(244, 245, 253, 1);
$secondary-background: rgb(196, 217, 255);

:root {
  // Base
  --border-radius: 15px;
  --page-padding: 24px;
  --header-height: 64px;
  --secondary-background: 196, 217, 255;
  --header-background: 0, 59, 88;
  --text-color: #252525;

  // @ibiliaze/react-base
  --main-background: 244, 245, 253, 1 !important;
  --custom-page-padding: var(--page-padding) !important;
  --custom-page-header-height: var(--header-height) !important;

  // @ibiliaze/reactstrap
  --ibiliaze-reactrap-border-radius: var(--border-radius) !important;
}
